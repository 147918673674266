import { Component, ContextType } from 'react';
import * as api from '../api';
import { userAPI } from '../config';
import { currentUserURL } from '../config/UserAPIConfig';
import ConfirmModal from '../generic/ConfirmModal';
import OptionList from '../generic/OptionList';
import Page from '../generic/Page';
import RadioButton from '../generic/RadioButton';
import { UserContext } from '../login/userContext';
import { ConsentTemplate, ErrorType } from '../model/types';

interface State {
    error?: string | ErrorType | null;
    isLoading: boolean;
    isOpen: boolean;
    consent?: ConsentTemplate;
    accepted?: boolean;
}

export default class ConsentConfirmationPage extends Component<any, State> {
    state: State = {
        isOpen: false,
        isLoading: false,
    };

    static contextType = UserContext;
    context!: ContextType<typeof UserContext>;

    componentDidMount() {
        this.fetchConsent();
    }

    selectChoice = (accepted: boolean) => {
        this.setState({
            accepted: accepted,
        });
    };

    onContinue = () => {
        if (this.state.accepted === true) {
            this.acceptConsent();
        } else if (this.state.accepted === false) {
            this.setState({ isOpen: true });
        } else {
            this.setState({ error: 'Du må gjøre et valg' });
        }
    };

    closeModal = () => {
        this.setState({ isOpen: false });
    };

    fetchConsent = async () => {
        this.setState({
            error: null,
            isLoading: true,
        });

        try {
            const consent = await api.get(userAPI.consentURL());
            this.setState({ consent });
        } catch (error: any) {
            this.setState({ error });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    acceptConsent = async () => {
        this.setState({
            error: null,
            isLoading: true,
        });
        try {
            await api.post(userAPI.consentURL(this.state.consent?.version));
            await this.context.refreshUser();
            window.location.replace('/');
        } catch (error: any) {
            this.setState({ error });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    closeAccount = async () => {
        this.setState({
            error: null,
            isLoading: true,
        });
        try {
            await api.delete(currentUserURL());
            await this.context.logout();
            window.location.replace('/');
        } catch (error: any) {
            this.setState({ error });
        } finally {
            this.setState({
                isOpen: false,
                isLoading: false,
            });
        }
    };

    renderContent = () => {
        if (!this.state.consent) {
            return null;
        }
        return (
            <div className="form-section-content">
                <p>
                    Vinmonopolet vil behandle opplysninger om deg i samsvar med gjeldende regler for personvern. Med personopplysninger mener vi navn,
                    adresse, fødselsdato, e-postadresse og telefonnummer. Vi trenger ditt samtykke til at vi kan lagre og bruke disse opplysningene
                    for å kontakte deg, levere varer og vite hvilke varer du har lov til å kjøpe.
                </p>
                <p>
                    Les{' '}
                    <a href="/personvern" target="_blank">
                        Vinmonopolets personvernerklæring
                    </a>{' '}
                    før du samtykker.
                </p>

                <OptionList ariaLabel="Aksepter eller avvis håndtering av personopplysninger">
                    <label className="option-list-item-content" onClick={() => this.selectChoice(true)}>
                        <RadioButton
                            selected={!!this.state.accepted}
                            value={this.state.consent.description}
                            onSelect={() => this.selectChoice(true)}
                            name="personopplysninger"
                        />
                        {this.state.consent.description}
                    </label>
                    <label className="option-list-item-content" onClick={() => this.selectChoice(false)}>
                        <RadioButton
                            selected={!!this.state.accepted}
                            value={this.state.consent.descriptionreject}
                            onSelect={() => this.selectChoice(false)}
                            name="personopplysninger"
                        />
                        {this.state.consent.descriptionreject}
                    </label>
                </OptionList>

                <div className="form-buttons">
                    <button className="btn-primary" onClick={this.onContinue}>
                        Fortsett
                    </button>
                </div>
            </div>
        );
    };

    render() {
        return (
            <Page title="Behandling av personopplysninger" isSpinning={this.state.isLoading} error={this.state.error} className="my-page">
                <section className="form-section white medium top">{this.renderContent()}</section>
                <ConfirmModal
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal}
                    onConfirm={this.closeAccount}
                    confirmButtonText="Slett konto"
                    heading="Advarsel"
                    mainText="Vennligst bekreft at du ønsker å slette din konto på Vinmonopolet.no"
                />
            </Page>
        );
    }
}
