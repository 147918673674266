import { useState } from 'react';
import { UserData, addEmailReminder } from '../../api/product';
import ErrorBox, { ErrorBoxType } from '../../generic/ErrorBox';
import Modal from '../../generic/Modal';
import OkCancelButtons from '../../generic/OkCancelButtons';
import Spinner from '../../generic/Spinner';
import useBooleanState from '../../utils/useBooleanState';
import { ProductCardDetails } from '../ProductCard';
import ProductMiniCard from '../ProductMiniCard';

export interface Props {
    isOpen: boolean;
    product: ProductCardDetails;
    onClose(): void;
    onConfirmReminder(userData: UserData): void;
}

export default function EmailRemainderModal({ isOpen, product, onClose, onConfirmReminder }: Props) {
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const onOk = () => {
        setError(null);
        setIsLoading.toTrue();

        addEmailReminder(product.code).then(onConfirmReminder, setError).finally(setIsLoading.toFalse);

        gtag('event', 'modal_view', {
            modal_name: 'ProductEmailReminder',
            link_text: 'Bekreft varsling',
        });
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen} size="small" padding>
            <h2>Bekreft varsling</h2>
            <p>Det vil bli sendt en e-post til adressen du har registrert på din bruker hvis produktet blir tilgjengelig igjen fra vår leverandør.</p>
            <ProductMiniCard product={product} alignment="center" />
            <ErrorBox errors={error} />
            <Spinner isSpinning={isLoading} />
            <OkCancelButtons onCancel={onClose} onOK={onOk} okButtonText="Bekreft varsling" centered />
        </Modal>
    );
}
