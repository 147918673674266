import { SanityDocument } from '@sanity/client';
import { ReactPageFunction } from '../../js/cloudflare/types';
import SanityHeaderFooterLayout, { SanityPageDocument } from '../../js/layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../../js/sanity/ArticleInfo';
import { CombineDataAttribute, ScopeDataAttribute } from '../../js/sanity/DataAttributeContext';
import { BannerImage, CaptionedSanityImage, ImageWithMetadata, imageWithMetadataGroq } from '../../js/sanity/SanityImage';
import SanityPage from '../../js/sanity/SanityPage';
import getSanityPageProps, { SanityModule, SanityProps } from '../../js/sanity/getSanityPageProps';
import groq from '../../js/sanity/groq';
import { RichText, richTextGroq } from '../../js/sanity/richTextComponents';
import { setCacheControl } from '../../js/utils/responseHelpers';

interface Recipe extends SanityDocument {
    _key: string;
    recipeImage?: ImageWithMetadata;
    name: string;
    chef?: {
        name?: string;
        role?: string;
    };
    servings: number;
    multistep?: boolean;
    recipes?: {
        _key: string;
        name: string;
        ingredients?: {
            _key: string;
            name: string;
            quantity: number;
            comment?: string;
        }[];
        richText: any;
    }[];
    ingredients?: {
        _key: string;
        name: string;
        quantity: number;
        comment?: string;
    }[];
    richText: any;
}

interface RecipePage extends SanityPageDocument {
    wineMagazineEdition?: {
        releaseNumber: string;
        pdf?: {
            size: number;
            url: string;
        };
    };
    richText: any;
    recipe?: Recipe;
    authors?: {
        name?: string;
    }[];
}

const query = groq`
    *[_type == "recipeArticle" && slug.current == $slug][0]{
        _id,
        _type,
        _updatedAt,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        wineMagazineEdition -> {
            releaseNumber,
            'pdf': pdf.asset -> {
                size,
                url
            }
        },
        richText[] ${richTextGroq},
        authors[] -> {
            name
        },
        recipe -> {
            _id,
            _type,
            recipeImage ${imageWithMetadataGroq},
            name,
            chef -> {
                name,
                role
            },
            servings,
            multistep,
            recipes[] {
                _key,
                name,
                ingredients[] {
                    _key,
                    name,
                    quantity,
                    comment
                },
                richText[] ${richTextGroq}
            },
            ingredients[] {
                _key,
                name,
                quantity,
                comment
            },
            richText[] ${richTextGroq}
        }
    }
`;

export const onRequestGet: ReactPageFunction<'/oppskrifter/[slug]', SanityProps<RecipePage>> = async ({ renderToResponse, next, params, ...ctx }) => {
    const response = await getSanityPageProps<RecipePage>(ctx, query, { slug: params.slug });

    if (response.notFound) {
        return next();
    }

    return renderToResponse(response) //
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<RecipePage>(({ page }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={16 / 9} />
        <section>
            <ArticleInfo wineMagazineEdition={page.wineMagazineEdition} authors={page.authors} chef={page.recipe?.chef} />
            <RichText value={page.richText} />

            {!!page.recipe && (
                <CombineDataAttribute id={page.recipe._id} type={page.recipe._type}>
                    <Recipe {...page.recipe} />
                </CombineDataAttribute>
            )}
            <ArticleInfo lastUpdated={page._updatedAt} />
        </section>
    </article>
));

function Recipe({ name, recipeImage, servings, multistep, recipes, ingredients, richText }: Recipe) {
    return (
        <div className="recipe">
            <h2>{name}</h2>
            <CaptionedSanityImage
                image={recipeImage}
                widths={[320, 640, 1024, 1288]}
                sizes="(min-width: 1288) 1288px, 100vw"
                studioPath="recipeImage"
            />
            <p>Porsjoner: {servings}</p>
            {multistep ? (
                recipes?.map((r) => (
                    <ScopeDataAttribute key={r._key} path={[{ _key: r._key }]}>
                        <h3>{r.name}</h3>
                        <ul>
                            {r.ingredients?.map(({ _key, quantity, name, comment }) => (
                                <li key={_key}>
                                    {quantity} {name} {comment}
                                </li>
                            ))}
                        </ul>
                        <RichText value={r.richText} />
                    </ScopeDataAttribute>
                ))
            ) : (
                <>
                    <ul>
                        {ingredients?.map(({ _key, quantity, name, comment }) => (
                            <li key={_key}>
                                {quantity} {name} {comment}
                            </li>
                        ))}
                    </ul>
                    <RichText value={richText} />
                </>
            )}
        </div>
    );
}

export { SanityHeaderFooterLayout as StaticLayout };

import * as __SELF__ from './[slug]';
__SELF__ satisfies SanityModule<RecipePage>;
