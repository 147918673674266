import cn from 'classnames';
import { useContext } from 'react';
import { useToggle } from 'usehooks-ts';
import { ordersURL, wishlistURL } from '../config/SiteConfig';
import Icon from '../generic/Icon';
import Page from '../generic/Page';
import { UserContext } from '../login/userContext';
import AccountMenuItem from './AccountMenuItem';
import MagazineSubscription from './MagazineSubscription';

export default function AccountMenuPage() {
    const { user, isLoggedIn, logout, refreshUser: updateUser } = useContext(UserContext);

    const [isBusy, toggleBusy] = useToggle();

    const onClick = async () => {
        toggleBusy();
        await logout();
        location.href = '/';
    };

    const customerId = user?.sapCustomerId ?? '';

    return (
        <Page title="Min side" subTitle={'Kundenummer: ' + customerId} className="my-page">
            <div className="pagelist pagelink__container">
                <a className="button" href={wishlistURL()}>
                    <Icon className="icon-favorite-header" />
                    <span>Mine lister</span>
                </a>
                <a className="button" href={ordersURL()}>
                    <Icon className="icon-order-list" />
                    <span>Mine kjøp</span>
                </a>
            </div>
            <ul className="pagelist">
                <AccountMenuItem title="Min profil" url="update-profile" icon="icon-my-profile" />
                <AccountMenuItem title="Mine adresser" url="address-book" icon="icon-location-pin" />
                <AccountMenuItem title="Mine betalingskort" url="payment-details" icon="icon-payment-info" />
                <AccountMenuItem title="Mitt Vinmonopol" url="store" icon="icon-my-store" />
                <AccountMenuItem title="Mine varslinger" url="notifications" icon="icon-my-notifications" />
                <AccountMenuItem title="Personvern" url="consents" icon="icon-privacy" />
            </ul>
            <div className="pagelist form-buttons">
                <button disabled={isBusy} className={cn('btn-secondary', { 'btn-spinner': isBusy })} onClick={onClick}>
                    Logg ut
                </button>
            </div>
            {isLoggedIn && <MagazineSubscription subscribed={user.magazine} onSunscriptionChange={updateUser} />}
        </Page>
    );
}
