import { ReactPageFunction } from '../../js/cloudflare/types';
import SanityHeaderFooterLayout, { SanityPageDocument } from '../../js/layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../../js/sanity/ArticleInfo';
import { BannerImage, imageWithMetadataGroq } from '../../js/sanity/SanityImage';
import SanityPage from '../../js/sanity/SanityPage';
import getSanityPageProps, { SanityModule, SanityProps } from '../../js/sanity/getSanityPageProps';
import groq from '../../js/sanity/groq';
import { RichText, richTextGroq } from '../../js/sanity/richTextComponents';
import { setCacheControl } from '../../js/utils/responseHelpers';

interface DrinkRecommendationPage extends SanityPageDocument {
    richText: any;
    disclaimer?: {
        title?: string;
        richText?: any;
    };
}

const query = groq`
    *[_type == "drinkRecommendationArticle" && slug.current == $slug][0]{
        _id,
        _type,
        _updatedAt,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        richText[] ${richTextGroq},
        authors[] -> {
            name,
            role
        },
        disclaimer -> {
            ...
        }
    }
`;

export const onRequestGet: ReactPageFunction<'/drikke-til-mat/[slug]', SanityProps<DrinkRecommendationPage>> = async ({
    renderToResponse,
    next,
    params,
    ...ctx
}) => {
    const response = await getSanityPageProps<DrinkRecommendationPage>(ctx, query, { slug: params.slug });

    if (response.notFound) {
        return next();
    }

    return renderToResponse(response) //
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<DrinkRecommendationPage>(({ page }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={16 / 9} />
        <section>
            <ArticleInfo authors={page.authors} />
            <RichText value={page.richText} />
            <ArticleInfo lastUpdated={page._updatedAt} />
            {page.disclaimer && (
                <>
                    <hr />
                    <aside className="disclaimer">
                        <h2>{page.disclaimer.title}</h2>
                        <RichText value={page.disclaimer.richText} studioPath={['disclaimer', 'richText']} />
                    </aside>
                </>
            )}
        </section>
    </article>
));

export { SanityHeaderFooterLayout as StaticLayout };

import * as __SELF__ from './[slug]';
__SELF__ satisfies SanityModule<DrinkRecommendationPage>;
