import { ReactPageFunction } from '../../js/cloudflare/types';
import SanityHeaderFooterLayout, { SanityPageDocument } from '../../js/layout/SanityHeaderFooterLayout';
import DrinkRecommendationItem, { DrinkRecommendation } from '../../js/sanity/DrinkRecommendationItem';
import Grid from '../../js/sanity/Grid';
import { imageWithMetadataGroq } from '../../js/sanity/SanityImage';
import SanityPage from '../../js/sanity/SanityPage';
import getSanityPageProps, { SanityModule, SanityProps } from '../../js/sanity/getSanityPageProps';
import groq from '../../js/sanity/groq';
import { setCacheControl } from '../../js/utils/responseHelpers';

interface ListPage extends SanityPageDocument {
    list: DrinkRecommendation[];
}

interface Props {
    tags: string[];
}

const query = groq`
    *[_type == "listPage" && slug.current == "drikke-til-mat"][0]{
        _id,
        _type,
        slug,
        title,
        ingress,
        metadata,
        'list': *[_type == ^.listType]{
            _id,
            _type,
            slug,
            title,
            ingress,
            bannerImage ${imageWithMetadataGroq},
            tags[] -> {
                _id,
                name,
                slug,
                mainTagCategory -> {
                    _id,
                    name,
                    slug
                }
            }
        } | order(title asc)
    }
`;
export const onRequestGet: ReactPageFunction<'/drikke-til-mat', SanityProps<ListPage, Props>> = async ({
    renderToResponse,
    next,
    request,
    ...ctx
}) => {
    const response = await getSanityPageProps<ListPage>(ctx, query);

    if (response.notFound) {
        return next();
    }

    return renderToResponse({
        ...response,
        tags: new URL(request.url).searchParams.getAll('tag'),
    }).then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<ListPage, Props>(({ page, tags }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <Grid list={page.list} component={DrinkRecommendationItem} selectedTags={tags} />
    </article>
));

export { SanityHeaderFooterLayout as StaticLayout };

import * as __SELF__ from './';
__SELF__ satisfies SanityModule<ListPage, Props>;
