import { useEffect, useState } from 'react';
import Address, { AddressT } from '../../account/Address';
import AddressModal from '../../account/AddressModal';
import * as api from '../../api';
import { userAPI } from '../../config';
import ErrorBox from '../../generic/ErrorBox';
import Spinner from '../../generic/Spinner';
import useBooleanState, { toBooleanState } from '../../utils/useBooleanState';
import { useOnMount } from '../../utils/useComponentDidMount';
import useStateWithSideEffect from '../../utils/useStateWithSideEffect';
import CheckoutButton from './CheckoutButton';

export interface Props {
    selectedAddress?: AddressT;
    onSelectAddress(address: AddressT): void;
    onCancel(): void;
}

interface Response {
    addresses: AddressT[];
}

function trackOpenModal(open: boolean) {
    if (open)
        gtag('event', 'modal_view', {
            modal_name: 'AddAddress',
        });
}

export default function AddressFinder({ selectedAddress, onSelectAddress, onCancel }: Props) {
    const [addresses, setAddresses] = useState<AddressT[]>();
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [error, setError] = useState(null);
    const [isAddNewAddressOpen, setIsAddNewAddressOpen] = toBooleanState(useStateWithSideEffect(false as boolean, trackOpenModal));

    useEffect(() => {
        if (addresses?.length === 0) {
            setIsAddNewAddressOpen.toTrue();
        }
    }, [addresses]);

    useOnMount(async () => {
        setError(null);
        setIsLoading.toTrue();
        try {
            const { addresses } = await api.get<Response>(userAPI.addressesURL());
            setAddresses(addresses.filter((a) => a.id !== selectedAddress?.id));
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    });

    return (
        <div className="option-block">
            <h4>
                {!!selectedAddress ? 'Velg mottaker' : 'Endre mottaker'}
                {!!selectedAddress && <CheckoutButton buttonText="Avbryt" onButtonClick={onCancel} />}
            </h4>
            <div>
                {addresses && (
                    <div className="btn-list">
                        {addresses.map((address) => (
                            <button key={address.id} type="button" className="btn--white" onClick={() => onSelectAddress(address)}>
                                <Address address={address} />
                            </button>
                        ))}
                    </div>
                )}
                <Spinner isSpinning={isLoading} />
                <div className="right-button">
                    <CheckoutButton buttonText="Legg til ny adresse" onButtonClick={setIsAddNewAddressOpen.toTrue} className="btn-secondary" />
                </div>
                <ErrorBox errors={error} />
            </div>
            <AddressModal
                onConfirm={onSelectAddress}
                onClose={addresses?.length ? setIsAddNewAddressOpen.toFalse : onCancel}
                isOpen={isAddNewAddressOpen}
            />
        </div>
    );
}
