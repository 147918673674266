import { useEffect, useState } from 'react';
import * as api from '../api';
import { site, userAPI } from '../config';
import EmptyState from '../generic/EmptyState';
import { ErrorBoxType } from '../generic/ErrorBox';
import Page from '../generic/Page';
import Pagination from '../generic/Pagination';
import TabButton from '../generic/TabButton';
import { WrapTrackingContext } from '../generic/TrackingContext';
import { PaginationType } from '../model/types';
import * as style from '../order/order-history.module.less';
import * as url from '../utils/url';
import useBooleanState from '../utils/useBooleanState';
import OrderHistoryEntry, { OrderDetails } from './OrderHistoryEntry';

export default function OrderHistoryPage() {
    const [orders, setOrders] = useState<OrderDetails[] | null>(null);
    const [pagination, setPagination] = useState<PaginationType>();
    const [isCompleted, setIsCompleted] = useBooleanState(true);
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [error, setError] = useState<ErrorBoxType | null>(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        let page = parseInt(urlParams.get('currentPage') ?? '0');
        const orderType = urlParams.get('orderType') || 'active';
        if (!Number.isInteger(page) || page < 0) page = 0;
        updateOrders(page, orderType === 'completed', true);

        window.addEventListener('popstate', () => {
            const currentParams = new URLSearchParams(location.search);
            const page = parseInt(currentParams.get('currentPage') ?? '0');
            const orderType = currentParams.get('orderType') || 'active';
            updateOrders(page, orderType === 'completed', true);
        });
    }, []);

    const updateOrders = (page = 0, isOrderCompleted = isCompleted, replaceHistory = false) => {
        setIsLoading.toTrue();
        setError(null);
        setIsCompleted(isOrderCompleted);
        const orderCompleted = isOrderCompleted ? 'COMPLETED' : 'ACTIVE';
        const pageParam = page > 0 ? `&currentPage=${page.toString()}` : '';
        const urlParams = `orderType=${orderCompleted.toLowerCase()}${pageParam}`;
        url.setUrl(urlParams, replaceHistory);

        api.get(userAPI.orderHistoryURL(), {
            fields: 'FULL',
            pageSize: '10',
            orderType: orderCompleted,
            currentPage: page.toString(),
        })
            .then(({ orders, pagination }) => {
                // in case of bogus url currentPage param
                if (page > 0 && page >= pagination.totalPages) return updateOrders();
                setOrders(orders ?? []);
                setPagination(pagination);
            })
            .catch(setError)
            .finally(setIsLoading.toFalse);
    };

    return (
        <Page title="Mine kjøp" backUrl={site.accountURL()} isSpinning={isLoading} error={error}>
            {orders && pagination && (
                <>
                    <div role="tablist" className="tab-group--centered">
                        <WrapTrackingContext set={{ tab_name: 'mine kjøp' }}>
                            <TabButton selected={!isCompleted} onSelect={() => updateOrders(0, false)} large>
                                Aktive
                            </TabButton>
                            <TabButton selected={isCompleted} onSelect={() => updateOrders(0, true)} large>
                                Tidligere
                            </TabButton>
                        </WrapTrackingContext>
                    </div>
                    <div className={style.orderList}>
                        {orders.length === 0 ? (
                            <EmptyState
                                text={
                                    isCompleted
                                        ? 'Her vil du se kjøp som er avsluttet.'
                                        : 'Her vil du se aktive kjøp.\n Velg tidligere for å se kjøp som er avsluttet.'
                                }
                                icon="icon-order-list"
                                className={style.emptyState}
                            />
                        ) : (
                            <>
                                <span className="sr-only">{!isCompleted ? 'Mine aktive kjøp' : 'Mine tidligere kjøp'}</span>
                                {orders.map((orderDetails) => (
                                    <OrderHistoryEntry
                                        key={orderDetails.code}
                                        currentPage={pagination?.currentPage}
                                        completed={isCompleted}
                                        {...orderDetails}
                                    />
                                ))}
                            </>
                        )}
                    </div>

                    <Pagination {...pagination} onGotoPage={updateOrders} />
                </>
            )}
        </Page>
    );
}
