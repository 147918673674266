import { useState } from 'react';
import Expandable from '../../../js/Expandable/Expandable';
import * as api from '../../../js/api';
import { site } from '../../../js/config';
import * as faqApi from '../../../js/config/FaqAPIConfig';
import Page from '../../../js/generic/Page';
import HeaderFooterLayout, { AssetProps } from '../../../js/layout/HeaderFooterLayout';
import NotFoundPage from '../../../js/notFoundPage';
import * as url from '../../../js/utils/url';
import useBooleanState from '../../../js/utils/useBooleanState';
import { useOnMount } from '../../../js/utils/useComponentDidMount';
import * as style from '../faq-page.module.less';

interface Response {
    label: string;
    articles: (Article & {
        id: string;
    })[];
}

export default function FaqCategoryPage() {
    const [category, setCategory] = useState<Response>();
    const [error, setError] = useState<unknown>(null);
    const [isLoading, setIsLoading] = useBooleanState(true);

    useOnMount(() => {
        const categoryId = url.getIdFromPath(document.location);
        api.getAnonymously<Response>(faqApi.getCategoryURL(categoryId!)).then(setCategory, setError).finally(setIsLoading.toFalse);
    });

    if (error instanceof api.NotFoundError) {
        return <NotFoundPage />;
    }

    return (
        <Page title={category?.label} backUrl={site.faqURL()} isSpinning={isLoading} error={error}>
            {category?.articles && (
                <ul className="pagelist">
                    {category.articles.map(({ id, question, urlName, answer }) => (
                        <FaqArticle key={id} question={question} urlName={urlName} answer={answer} />
                    ))}
                </ul>
            )}
        </Page>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Ofte stilte spørsmål (FAQ)" />;
}

function checkMatchingUrlParam(urlName: string) {
    const url = new URL(window.location.href);
    return urlName === url.searchParams.get('urlName');
}

function updateUrlParam(urlName: string, expanded: boolean) {
    const url = new URL(window.location.href);
    if (expanded) {
        url.searchParams.set('urlName', urlName);
    } else {
        url.searchParams.delete('urlName');
    }
    history.replaceState(null, '', url);
}

export interface Article {
    question: string;
    urlName: string;
    answer: string;
}

export function FaqArticle({ question, urlName, answer }: Article) {
    return (
        <li className={style['faq__list']}>
            <Expandable
                title={question}
                iconPlacement="left"
                isExpanded={checkMatchingUrlParam(urlName)}
                onClickHeading={(expanded: boolean) => updateUrlParam(urlName, expanded)}
            >
                <div className={style['faq__expandable-content']}>
                    <p dangerouslySetInnerHTML={{ __html: answer }} />
                </div>
            </Expandable>
        </li>
    );
}
