import { PropsWithChildren, useContext, useState } from 'react';
import * as api from '../api';
import { userAPI } from '../config';
import InfoBox from '../generic/InfoBox';
import Page from '../generic/Page';
import { UserContext } from '../login/userContext';
import { ErrorType } from '../model/types';
import delay from '../utils/delay';
import * as url from '../utils/url';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';

export default function EmailConfirmationPage() {
    const [verificationCode, setVerificationCode] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [verified, setVerified] = useState<boolean>();
    const [expired, setExpired] = useState<boolean>();
    const [error, setError] = useState<ErrorType | null>(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const { user, refreshUser } = useContext(UserContext);

    useOnMount(async () => {
        const verificationCode = url.getParameter('verificationCode');
        const newCustomer = url.getBooleanParameter('newCustomer');

        if (!!verificationCode && newCustomer !== undefined) {
            setVerificationCode(verificationCode);
            setIsLoading.toTrue();
            setError(null);

            try {
                const { verified, expired } = await api.post(userAPI.verifyEmailURL(), {
                    verificationCode,
                    newCustomer,
                });
                setVerified(verified);
                setExpired(expired);
                await refreshUser();
            } catch (error: any) {
                setError(error);
            } finally {
                setIsLoading.toFalse();
            }
        } else {
            while (true) {
                await refreshUser();
                await delay(60_000);
            }
        }
    });

    const resend = async () => {
        setIsLoading.toTrue();
        setError(null);

        try {
            if (verificationCode) {
                const { message } = await api.post(userAPI.resendVerificationEmailTokenExpiredURL(), { verificationCode });
                setMessage(message);
            } else {
                const { message } = await api.post(userAPI.resendVerificationEmailURL());
                setMessage(message);
            }
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    if (verified || user?.requiresEmailVerification === false) {
        return (
            <Wrapper heading="E-postadresse bekreftet">
                <p>
                    Velkommen, du har nå bekreftet din e-postadresse, <br />
                    og kan bruke sidene våre som innlogget bruker.
                </p>
                <a href={url.getRedirectUrl()}>Fortsett</a>
            </Wrapper>
        );
    } else if (expired) {
        return (
            <Wrapper heading="Bekreft din e-postadresse" isLoading={isLoading} error={error}>
                <p>Bekreftelseslenken har utløpt.</p>
                {message ? (
                    <InfoBox message={message} />
                ) : (
                    <button className="link btn-underline-link" onClick={resend}>
                        Bestill ny bekreftelseslenke
                    </button>
                )}
            </Wrapper>
        );
    } else if (!verificationCode) {
        return (
            <Wrapper heading="Bekreft din e-postadresse" isLoading={isLoading} error={error}>
                <p>
                    Vi har sendt deg en e-post med en bekreftelseslenke.
                    <br />
                    Vennligst klikk på lenken for å bekrefte e-postadressen.
                </p>
                <p>Har du ikke fått e-posten?</p>
                {message ? (
                    <InfoBox message={message} />
                ) : (
                    <button className="link btn-underline-link" onClick={resend}>
                        Bestill ny bekreftelseslenke
                    </button>
                )}
            </Wrapper>
        );
    } else {
        return <Wrapper heading="Bekreft din e-postadresse" isLoading={isLoading} error={error} />;
    }
}

function Wrapper({ heading, isLoading, error, children }: PropsWithChildren<{ heading: string; isLoading?: boolean; error?: any }>) {
    return (
        <Page title={heading} isSpinning={isLoading} error={error} className="my-page">
            <section className="form-section white medium top">
                <div className="form-section-content">{children}</div>
            </section>
        </Page>
    );
}
