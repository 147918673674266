import { useState } from 'react';
import * as api from '../api';
import { userAPI } from '../config';
import Checkbox from '../generic/CheckBox';
import ErrorBox from '../generic/ErrorBox';
import SpinnerContainer from '../generic/SpinnerContainer';
import Toast from '../generic/Toast/Toast';
import eventTargetChecked from '../utils/eventTargetChecked';
import useBooleanState from '../utils/useBooleanState';

export interface Props {
    subscribed: boolean;
    onSunscriptionChange(): void;
}

export default function MagazineSubscription({ subscribed, onSunscriptionChange }: Props) {
    const [isLoading, setIsLoading] = useBooleanState();
    const [error, setError] = useState<any>(null);

    const onCheckBoxChange = async (isChecked: boolean) => {
        setError(null);
        setIsLoading.toTrue();
        try {
            await api.patchJson(userAPI.magazineSubscriptionURL(), { subscribed: isChecked });
            Toast.call({ message: isChecked ? 'Du abonnerer nå på Vinbladet.' : 'Ditt abonnement på Vinbladet er nå avsluttet.' });

            onSunscriptionChange();
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <SpinnerContainer className="subscription-box" isSpinning={isLoading}>
            <h2 className="sub-headline">Jeg vil abonnere på Vinbladet - helt gratis</h2>
            <p>
                Kryss av for å få vårt kundemagasin Vinbladet hjem i postkassen fire ganger i året – helt gratis. Din adresse blir videreformidlet til
                vår leverandør som sender ut Vinbladet, men vil ikke bli brukt til noe annet.
            </p>
            <ErrorBox errors={error} className="margin-bottom" />
            <form className="form-magazine-action">
                <Checkbox id="magazine_subscription" label="Ja takk!" isChecked={subscribed} onChange={eventTargetChecked(onCheckBoxChange)} />
            </form>
        </SpinnerContainer>
    );
}
