import { useState } from 'react';
import { CardPaymentInfo, getCardPaymentInfo } from '../api/payment';
import { site } from '../config';
import Page from '../generic/Page';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import PaymentInfoListItem from './PaymentInfoListItem';

export default function PaymentInfoPage() {
    const [isLoading, setIsLoading] = useBooleanState();
    const [paymentMethods, setPaymentMethods] = useState<CardPaymentInfo[]>([]);
    const [error, setError] = useState<any>(null);

    const getPaymentInfo = async () => {
        setError(null);
        setIsLoading.toFalse();
        try {
            const { payments } = await getCardPaymentInfo();
            setPaymentMethods(payments);
        } catch (e: any) {
            setError(e);
        } finally {
            setIsLoading.toFalse();
        }
    };

    useOnMount(getPaymentInfo);

    const defaultCard = paymentMethods.filter((x) => x.defaultPayment);
    const savedCards = paymentMethods.filter((x) => !x.defaultPayment);

    return (
        <Page title="Mine betalingskort" backUrl={site.accountURL()} isSpinning={isLoading} error={error} className="my-page">
            <section className="form-section white top">
                <div className="form-section-info">
                    <h2>Mitt standardkort</h2>
                    <p>
                        Standardkortet blir automatisk satt som betalingsmåte når du handler. Det bør være det kortet du foretrekker skal velges ved
                        utsjekk.
                    </p>
                    <h2>Lagrede kort</h2>
                    <p>
                        Her vises kort du har lagret tidligere. Du kan legge til nytt betalingskort ved å velge "lagret kort" ved betaling av ordre.
                    </p>
                </div>
                {!!paymentMethods.length && (
                    <div className="form-section-content form-group">
                        <h2 className="label">Mitt standardkort</h2>
                        <ul className="card-list">
                            {defaultCard.map((paymentInfo) => (
                                <PaymentInfoListItem key={paymentInfo.id} paymentInfo={paymentInfo} onChange={getPaymentInfo} onError={setError} />
                            ))}
                        </ul>
                        {!!savedCards.length && (
                            <>
                                <h2 className="label">Lagrede kort</h2>
                                <ul className="card-list">
                                    {savedCards.map((paymentInfo) => (
                                        <PaymentInfoListItem
                                            key={paymentInfo.id}
                                            paymentInfo={paymentInfo}
                                            onChange={getPaymentInfo}
                                            onError={setError}
                                        />
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                )}
            </section>
        </Page>
    );
}
