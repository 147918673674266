import { useContext } from 'react';
import { getFormattedDate } from '../cms/CMSComponent';
import { DataAttributeContext } from './DataAttributeContext';

export interface ArticleInfoProps {
    wineMagazineEdition?: {
        releaseNumber: string;
        pdf?: {
            size: number;
            url: string;
        };
    };
    authors?: {
        name?: string;
    }[];
    chef?: {
        name?: string;
        role?: string;
    };
    lastUpdated?: string;
    firstPublished?: string;
}

export function ArticleInfo({ wineMagazineEdition, authors, chef, lastUpdated, firstPublished }: ArticleInfoProps) {
    const dataAttribute = useContext(DataAttributeContext);
    return (
        <p className="article-info">
            {!!wineMagazineEdition?.pdf?.url && (
                <span>
                    Først publisert i <a href={wineMagazineEdition.pdf.url}>Vinbladet {wineMagazineEdition.releaseNumber}</a>
                </span>
            )}
            {!!authors?.length && <span data-sanity={dataAttribute?.('authors')}>Tekst: {authors?.map(nameAndRole).filter(Boolean).join(', ')}</span>}
            {!!chef?.name && (
                <span data-sanity={dataAttribute?.(['recipe', 'chef'])}>
                    Oppskrift:
                    {nameAndRole(chef)}
                </span>
            )}
            {!!lastUpdated && (
                <span>
                    Sist oppdatert: <time dateTime={lastUpdated}>{getFormattedDate(lastUpdated)}</time>
                </span>
            )}
            {!!firstPublished && (
                <span>
                    Publisert: <time dateTime={firstPublished}>{getFormattedDate(firstPublished)}</time>
                </span>
            )}
        </p>
    );
}

function nameAndRole({ name, role }: { name?: string; role?: string }) {
    return role ? `${name} - ${role}` : name;
}
