import { ReactPageFunction } from '../../../js/cloudflare/types';
import Icon from '../../../js/generic/Icon';
import SanityHeaderFooterLayout, { SanityPageDocument } from '../../../js/layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../../../js/sanity/ArticleInfo';
import { BannerImage, imageWithMetadataGroq } from '../../../js/sanity/SanityImage';
import SanityPage from '../../../js/sanity/SanityPage';
import getSanityPageProps, { SanityModule, SanityProps } from '../../../js/sanity/getSanityPageProps';
import groq from '../../../js/sanity/groq';
import { RichText, richTextGroq } from '../../../js/sanity/richTextComponents';
import { setCacheControl } from '../../../js/utils/responseHelpers';

interface NewsPage extends SanityPageDocument {
    firstPublished: string;
    richText: any;
    pressContact?: {
        email: string;
        name: string;
        phone: string;
    };
}

const query = groq`
    *[_type == "newsArticle" && slug.current == $slug][0]{
        _id,
        _type,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        firstPublished,
        richText[] ${richTextGroq},
        pressContact -> {
            email,
            name,
            phone
        }
    }
`;
export const onRequestGet: ReactPageFunction<'/om-oss/aktuelt/[slug]', SanityProps<NewsPage>> = async ({
    renderToResponse,
    next,
    params,
    ...ctx
}) => {
    const response = await getSanityPageProps<NewsPage>(ctx, query, { slug: params.slug });

    if (response.notFound) {
        return next();
    }

    return renderToResponse(response) //
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<NewsPage>(({ page }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={2.7} />
        <section>
            <ArticleInfo firstPublished={page.firstPublished} />
            <RichText value={page.richText} />
            {!!page.pressContact && (
                <address className="press-contact">
                    <h2>{page.pressContact?.name}</h2>
                    {!!page.pressContact.email && (
                        <>
                            <Icon className="icon-email" />
                            <p>
                                <a href={`mailto:${page.pressContact.email}`}>{page.pressContact.email}</a>
                            </p>
                        </>
                    )}
                    {!!page.pressContact.phone && (
                        <>
                            <Icon className="icon-phone" />
                            <p>
                                <a href={`tel:${page.pressContact.phone}`}>{page.pressContact.phone}</a>
                            </p>
                        </>
                    )}
                </address>
            )}
        </section>
    </article>
));

export { SanityHeaderFooterLayout as StaticLayout };

import * as __SELF__ from './[slug]';
__SELF__ satisfies SanityModule<NewsPage>;
