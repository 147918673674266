import { ClientConfig, QueryParams, createClient } from '@sanity/client';
import { SanityProjectDetails } from '@sanity/image-url/lib/types/types';
import { QueryResponseInitial } from '@sanity/react-loader';
import { ComponentType } from 'react';
import { ReactPageFunction, VmpEnv } from '../cloudflare/types';
import { StaticLayoutProps } from '../layout/HeaderFooterLayout';
import { getConfig } from './getConfig';

export interface DraftSanityPageProps<T> {
    draftMode: true;
    initial: QueryResponseInitial<T>;
    params?: QueryParams;
    query: string;
    clientConfig: ClientConfig;
}

export type SanityPageProps<T> = (
    | DraftSanityPageProps<T>
    | {
          draftMode: false;
          initial: {
              data: T;
          };
      }
) & {
    notFound: boolean;
    sanityProjectDetails: SanityProjectDetails;
};

export type SanityProps<T, S = object> = S & SanityPageProps<T>;

export interface SanityModule<T, P = undefined> {
    onRequestGet: ReactPageFunction<string, SanityProps<T, P>>;
    default: ComponentType<SanityProps<T, P>>;
    StaticLayout: ComponentType<StaticLayoutProps<SanityProps<T, P>>>;
}

export default async function getSanityPageProps<T>({ env }: { env: VmpEnv }, query: string, params?: QueryParams): Promise<SanityPageProps<T>> {
    const clientConfig = getConfig(env);

    const client = createClient(clientConfig);

    const sanityProjectDetails: SanityProjectDetails = {
        dataset: clientConfig.dataset!,
        projectId: clientConfig.projectId!,
    };

    if (env.PREVIEW_MODE) {
        const response = await client.fetch<T, any>(query, params, { filterResponse: false });

        return {
            draftMode: true,
            notFound: response.result == undefined,
            initial: {
                data: response.result,
                sourceMap: response.resultSourceMap,
                perspective: clientConfig.perspective,
            },
            clientConfig,
            query,
            params,
            sanityProjectDetails,
        };
    } else {
        const response = await client.fetch<T, any>(query, params, { filterResponse: true });

        return {
            draftMode: false,
            notFound: response == undefined,
            initial: {
                data: response,
            },
            sanityProjectDetails,
        };
    }
}
