import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import GA4 from '../analytics/ga4';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Slot from '../utils/Slot';
import Favicons from './Favicons';
import { HandleError } from './HandleError';
import SkipLinks from './SkipLinks';

export interface Assets {
    ['main.js']: string;
    ['main.css']: string;
    [key: string]: string;
}

export interface AssetProps {
    assets: Assets;
}

export interface StaticLayoutProps<Props> extends AssetProps {
    props: Props;
    url: string;
}

export interface MetaTag {
    property?: string;
    name?: string;
    content?: string;
}

export interface Props extends AssetProps {
    title?: string;
    page_title?: string | false;
    robots?: string;
    pageLabel?: 'search' | 'cart' | 'checkout';
    className?: string | (string | undefined)[];
    meta?: MetaTag[];
    canonical?: string;
}

export default function HeaderFooterLayout({
    assets,
    title,
    robots,
    className,
    pageLabel,
    page_title,
    meta,
    canonical,
    children,
}: PropsWithChildren<Props>) {
    return (
        <html lang="no">
            <head>
                <meta charSet="utf-8" />
                <Preload assets={assets} />
                <title>{title ? `${title} - Vinmonopolet` : 'Vinmonopolet'}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="robots" content={robots ?? 'all'} />
                <Favicons assets={assets} />
                <HandleError />
                <script type="module" src={assets['main.js']}></script>
                <GA4 config={page_title === false ? { send_page_view: false } : { page_title: page_title ?? title }} />
                <link rel="stylesheet" href={assets['main.css']} />
                <meta name="apple-itunes-app" content="app-id=1080930756" />
                {!!canonical && <link rel="canonical" href={canonical} />}
                {meta
                    ?.filter(({ name, content, property }) => !!content && (!!name || !!property))
                    .map(({ name, content, property }) => <meta name={name} property={property} content={content} key={`${property}_${name}`} />)}
            </head>
            <body className={classNames('site', className)}>
                <div data-react-component="SkipLinks" data-react-props={JSON.stringify({ pageLabel })}>
                    <SkipLinks pageLabel={pageLabel} />
                </div>
                <header className="site__header" data-react-component="Header">
                    <Header />
                </header>
                {children ?? <Slot elm="main" className="site__body" id="page" tabIndex={-1} />}
                <footer className="site__footer" id="site-footer" tabIndex={-1} data-react-component="Footer">
                    <Footer />
                </footer>
            </body>
        </html>
    );
}

export function Preload({ assets }: AssetProps) {
    return (
        <>
            <link rel="preload" href={assets['assets/VinmonopoletTittel-Bold.woff2']} as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href={assets['assets/VinmonopoletIngress-Medium.woff2']} as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href={assets['assets/robotoFlex.woff2']} as="font" type="font/woff2" crossOrigin="anonymous" />
        </>
    );
}
