import { useState } from 'react';
import { getProductImages } from '../api/product';
import Page from '../generic/Page';
import { useOnMount } from '../utils/useComponentDidMount';
import GiftCardForm from './GiftCardForm';

export interface State {
    egavekort: string;
    gavekort: string;
}

export default function GiftcardBalanceCheckerPage() {
    const [egavekort, setEgavekort] = useState('');
    const [gavekort, setGavekort] = useState('');

    useOnMount(() => {
        getProductImages('egavekort').then(selectProductImage).then(setEgavekort);
        getProductImages('gavekort').then(selectProductImage).then(setGavekort);
    });

    return (
        <Page title="Sjekk saldo på gavekort">
            <section className="white centered">
                <div className="check-giftcard-balance-cards">
                    <img src={egavekort} alt="bilde av Vinmonopolets digitale gavekort" />
                    <img src={gavekort} alt="bilde av Vinmonopolets fysiske gavekort" />
                </div>
            </section>
            <section className="white form-section">
                <div className="form-section-info">
                    <p>Fyll ut gavekortnummer og pinkode for å sjekke saldo.</p>
                </div>
                <GiftCardForm />
            </section>
        </Page>
    );
}

function selectProductImage(images: { format: string; url: string }[]) {
    return images.find((image) => image.format === 'product')?.url ?? '';
}
