import { useContext, useState } from 'react';
import { UserData, removeEmailReminder } from '../../api/product';
import ConfirmModal from '../../generic/ConfirmModal';
import ErrorBox, { ErrorBoxType } from '../../generic/ErrorBox';
import Icon from '../../generic/Icon';
import { UserContext } from '../../login/userContext';
import preventDefault from '../../utils/preventDefault';
import { toBooleanState } from '../../utils/useBooleanState';
import useStateWithSideEffect from '../../utils/useStateWithSideEffect';
import { DetailedProduct } from '../productdetails/ProductDetailsPage';
import EmailReminderModal from './EmailReminderModal';

export interface Props {
    product: DetailedProduct;
    statusNotificationRegistered: boolean | undefined;
    onUserDataChange(userData: UserData): void;
}

export default function ProductEmailReminder({ product, statusNotificationRegistered, onUserDataChange }: Props) {
    if (!product.statusNotification) return null;

    return statusNotificationRegistered ? (
        <RemoveNotification product={product} onUserDataChange={onUserDataChange} />
    ) : (
        <AddNotification product={product} onUserDataChange={onUserDataChange} />
    );
}

function trackOpenAddModal(open: boolean) {
    if (open)
        gtag('event', 'modal_view', {
            modal_name: 'ProductEmailReminder',
            link_text: 'Send meg en epost hvis varen blir tilgjengelig',
        });
}

function AddNotification({ product, onUserDataChange }: Omit<Props, 'statusNotificationRegistered'>) {
    const [isModalOpen, setIsModalOpen] = toBooleanState(useStateWithSideEffect(false as boolean, trackOpenAddModal));

    const { showLoginModal } = useContext(UserContext);

    const onClick = () => {
        if (!showLoginModal()) {
            setIsModalOpen.toTrue();
        }
    };

    return (
        <div className="product-email-reminder">
            <button className="no-print btn-tertiary" onClick={preventDefault(onClick)}>
                <Icon className="icon-email" />
                <span>Send meg en epost hvis varen blir tilgjengelig</span>
            </button>
            <EmailReminderModal
                isOpen={isModalOpen}
                product={product}
                onClose={setIsModalOpen.toFalse}
                onConfirmReminder={(userData) => {
                    setIsModalOpen.toFalse();
                    onUserDataChange(userData);
                }}
            />
        </div>
    );
}

function trackOpenRemoveModal(open: boolean) {
    if (open)
        gtag('event', 'modal_view', {
            modal_name: 'ProductEmailReminder',
            link_text: 'Fjern varsling',
        });
}

function RemoveNotification({ product, onUserDataChange }: Omit<Props, 'statusNotificationRegistered'>) {
    const [isModalOpen, setIsModalOpen] = toBooleanState(useStateWithSideEffect(false as boolean, trackOpenRemoveModal));
    const [error, setError] = useState<ErrorBoxType | null>(null);

    const onDelete = () => {
        setError(null);
        removeEmailReminder(product.code).then(onUserDataChange, setError).finally(setIsModalOpen.toFalse);

        gtag('event', 'modal_view', {
            modal_name: 'ProductEmailReminder',
            link_text: 'Slett varsling',
        });
    };
    return (
        <div className="product-email-reminder">
            <ErrorBox errors={error} />
            <div className="info-message">
                <div className="message-text">Du vil få en e-post hvis varen blir tilgjengelig</div>
                <button
                    onClick={preventDefault(setIsModalOpen.toTrue)}
                    type="button"
                    aria-label={`Fjern varsling for ${product.name}`}
                    className="btn--transparent"
                >
                    <Icon className="icon-delete black" />
                </button>
            </div>
            <ConfirmModal
                onClose={setIsModalOpen.toFalse}
                onConfirm={onDelete}
                mainText="Ønsker du å slette varsling?"
                confirmButtonText="Slett varsling"
                isOpen={isModalOpen}
            />
        </div>
    );
}
