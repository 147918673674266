import image from '../../images/svg/stacking-bottles-illustration.svg';

const html = `
<div class="outdated-browser--popup">
    <h1>Utdatert nettleser</h1>
    <img src="${image}">
    <p>Vi beklager, Vinmonopolet.no støtter ikke lenger den nettleseren du bruker.</p>
    <p>Vennligst last ned en av følgende oppdaterte, gratis og gode nettlesere:</p>
    <p style="text-align: left">
        <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>
        <br />
        <a href="http://www.mozilla.com/firefox/" target="_blank">Mozilla Firefox</a>
        <br />
        <a href="https://www.apple.com/safari/" target="_blank">Safari</a>
        <br />
        <a href="https://www.microsoft.com/nb-no/windows/microsoft-edge" target="_blank">Microsoft Edge</a>
    </p>
    <details>
        <summary>Vis feilmelding</summary>
        <code>
            {message}
            <br><br>
            {file} {lineno}:{colno}
            <br>
            <pre>{stack}</pre>
            <br>
            {userAgent}
        </code>
    </details>
</div>
`
    .split('\n')
    .map((s) => s.trim())
    .join('\n');

export function HandleError() {
    const script = `
if(!${USER_AGENT_REGEX}.test(navigator.userAgent)){
    function handleError(event) {
        if(document.getElementsByClassName('outdated-browser').length) return;
        console.log(event);
        var elm = document.createElement('div');
        elm.setAttribute('class', 'outdated-browser');
        elm.innerHTML = ${JSON.stringify(html)}
            .replace('{message}', event.error)
            .replace('{file}', event.filename)
            .replace('{lineno}', event.lineno)
            .replace('{colno}', event.colno)
            .replace('{stack}', event.error instanceof Error ? event.error.stack : '')
            .replace('{userAgent}', navigator.userAgent);
        document.documentElement.appendChild(elm);
        document.documentElement.style.overflow = 'hidden';
    }
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', function(event) { throw event.reason; });
}
`;
    return (
        <>
            <script type="text/javascript" dangerouslySetInnerHTML={{ __html: script }}></script>
            <script
                type="text/javascript"
                noModule
                dangerouslySetInnerHTML={{ __html: "throw new Error('Browser does not support modules');" }}
            ></script>
        </>
    );
}
