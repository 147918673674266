import { useContext, useState } from 'react';
import { CartContext } from '../cart/CartContext';
import ErrorBox from '../generic/ErrorBox';
import { InputGroup } from '../generic/forms';
import SubmitButton from '../generic/forms/SubmitButton';
import { ErrorType } from '../model/types';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState, { toBooleanState } from '../utils/useBooleanState';
import useStateWithSideEffect from '../utils/useStateWithSideEffect';
import { isEmptyString, isValidEmail } from '../utils/validation';
import ForgottenPasswordModal from './ForgottenPasswordModal';
import { UserContext } from './userContext';

export interface Props {
    onLoggedIn(): void;
}

function trackOpenModal(open: boolean) {
    if (open)
        gtag('event', 'modal_view', {
            modal_name: 'ForgottenPassword',
        });
}

export default function LoginForm({ onLoggedIn }: Props) {
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState<ErrorType>();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<ErrorType>();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();
    const [isForgottenPasswordShowing, setIsForgottenPasswordShowing] = toBooleanState(useStateWithSideEffect(false as boolean, trackOpenModal));

    const { login } = useContext(UserContext);
    const cart = useContext(CartContext);

    const handleSubmit = async () => {
        const usernameError = isValidEmail(username) ? undefined : { message: 'Du må fylle ut en gyldig e-postadresse.' };
        const passwordError = isEmptyString(password) ? { message: 'Du må fylle ut passord.' } : undefined;

        setUsernameError(usernameError);
        setPasswordError(passwordError);

        if (usernameError || passwordError) {
            return;
        }

        setIsLoading.toTrue();
        setError(null);

        try {
            const { cartId } = await cart.getCartAndUserId();
            await login(username.toLowerCase(), password);
            await cart.mergePreviousCart(cartId);
            onLoggedIn();
            gtag('event', 'login', { method: 'password' });
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <>
            <section className="centered login-section">
                <ErrorBox errors={error} className="margin-bottom" />
                <p>Hvis du allerede har registrert deg som bruker kan du logge inn her.</p>
                <form onSubmit={preventDefault(handleSubmit)} noValidate>
                    <InputGroup
                        label="E-postadresse *"
                        id="username"
                        name="username"
                        value={username}
                        onChange={eventTargetValue(setUsername)}
                        onInitialValue={setUsername}
                        error={usernameError}
                        type="email"
                        className="half-width"
                        autoComplete="email"
                        disabled={isLoading}
                    />
                    <InputGroup
                        label="Passord *"
                        id="password"
                        name="password"
                        value={password}
                        onChange={eventTargetValue(setPassword)}
                        onInitialValue={setPassword}
                        error={passwordError}
                        type="password"
                        className="half-width right"
                        autoComplete="current-password"
                        disabled={isLoading}
                    />
                    <div className="form-buttons-nomargin">
                        <a onClick={setIsForgottenPasswordShowing.toTrue}>Glemt passord?</a>
                        <SubmitButton isLoading={isLoading}>Logg inn</SubmitButton>
                    </div>
                </form>
            </section>
            {isForgottenPasswordShowing && <ForgottenPasswordModal username={username} onClose={setIsForgottenPasswordShowing.toFalse} />}
        </>
    );
}
