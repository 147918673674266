import Modal from '../generic/Modal';
import Toast from '../generic/Toast/Toast';
import { AddressT } from './Address';
import AddressForm from './AddressForm';

export interface Props {
    address?: AddressT;
    isOpen: boolean;
    onClose(): void;
    onConfirm(address: AddressT): void;
}

export default function AddressModal({ address, isOpen, onClose, onConfirm }: Props) {
    const message = address == null ? 'Adressen er lagret.' : 'Endringer er lagret.';

    return (
        <Modal onClose={onClose} isOpen={isOpen} padding>
            <AddressForm
                address={address}
                headerText={address == null ? 'Legg til ny adresse' : 'Endre adresse'}
                onSavedAddress={(address) => {
                    Toast.call({ message });
                    onConfirm(address);
                }}
                onCancel={onClose}
            />
        </Modal>
    );
}
