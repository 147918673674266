import type { SanityDocument } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ComponentType, lazy } from 'react';
import { ImageUrlBuilderContext } from './ImageUrlBuilderContext';
import { SanityProps } from './getSanityPageProps';

const SanityLiveMode = lazy(() => import('../../js/sanity/SanityLiveMode'));

export type SanityPageComponent<T extends SanityDocument, P> = ComponentType<{ page: T } & P>;

export default function SanityPage<T extends SanityDocument, P = object>(Component: SanityPageComponent<T, P>) {
    return (props: SanityProps<T, P>) => (
        <ImageUrlBuilderContext.Provider value={imageUrlBuilder(props.sanityProjectDetails)}>
            {props.draftMode ? (
                <SanityLiveMode {...props} component={Component as any} />
            ) : (
                <Component page={props.initial.data} {...otherProps(props)} />
            )}
        </ImageUrlBuilderContext.Provider>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function otherProps<T, P>({ draftMode, initial, notFound, sanityProjectDetails, ...props }: SanityProps<T, P>): any {
    return props;
}
