import { PortableText, PortableTextBlock } from '@portabletext/react';
import type { SanityDocument } from '@sanity/client';
import { ReactPageFunction } from '../js/cloudflare/types';
import HeaderFooterLayout, { StaticLayoutProps } from '../js/layout/HeaderFooterLayout';
import SanityPage from '../js/sanity/SanityPage';
import getSanityPageProps, { SanityProps } from '../js/sanity/getSanityPageProps';
import { setCacheControl } from '../js/utils/responseHelpers';

interface PocPage extends SanityDocument {
    slug: { current: string };
    title: string;
    subtitle: string;
    portableText: PortableTextBlock;
}

const query = `
    *[_type == "pocPage" && slug.current == "informasjonskapsler"][0]{
        slug,
        title,
        subtitle,
        portableText
    }
`;
export const onRequestGet: ReactPageFunction<'/informasjonskapsler', SanityProps<PocPage>> = async ({ renderToResponse, next, ...ctx }) => {
    const response = await getSanityPageProps<PocPage>(ctx, query);

    if (response.notFound) {
        return next();
    }

    return renderToResponse(response) //
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<PocPage>(({ page }) => (
    <article className="sanity">
        <div className="breadcrumbs"></div>
        <header>
            <h1>{page.title}</h1>
            <p>{page.subtitle}</p>
        </header>
        <section>
            <PortableText value={page.portableText} />
        </section>
    </article>
));

export function StaticLayout({ assets, props }: StaticLayoutProps<PocPage>) {
    return <HeaderFooterLayout assets={assets} title={props.title} />;
}
