import { Component } from 'react';
import * as api from "../api";
import { site, userAPI } from "../config";
import { currentUserURL } from "../config/UserAPIConfig";
import ConfirmModal from "../generic/ConfirmModal";
import ErrorBox, { ErrorBoxType } from "../generic/ErrorBox";
import Page from "../generic/Page";
import { ConsentTemplate } from "../model/types";

interface State {
    error?: string | ErrorBoxType | null;
    isLoading: boolean;
    isOpen: boolean;
    consent?: ConsentTemplate;
}

export default class ConsentManagementPage extends Component<any, State> {

    state: State = {
        isOpen: false,
        error: null,
        isLoading: true
    };

    componentDidMount() {
        this.fetchConsent();
    }

    fetchConsent = () => {
        api.get(userAPI.consentURL())
            .then((data) => {
                this.setState({
                    consent: data,
                    isLoading: false
                });
            },
            (error) => {
                this.setState({
                    error: error,
                    isLoading: false
                });
            }
        );
    }

    oncloseAccount = () => {
        this.setState({isOpen: true});
    };

    closeModal = () => {
        this.setState({isOpen: false});
    };

    closeAccount = () => {
        this.setState({
            error: null,
            isLoading: true
        });
        api.delete(currentUserURL())
            .then(() => {
                this.setState({
                    isLoading: false
                });
                location.href = '/';
            },
            (error) => {
                this.setState({
                    error: error,
                    isLoading: false
                });
            });
    };

    renderContent = () => {
        if (this.state.consent?.consentData?.givenDate) {
            const {formattedValue, readableValue} = this.state.consent.consentData.givenDate;

            return (
                <div className='form-section-content'>
                    <p>Du aksepterte <a href={'/personvern'}>Vinmonopolets regler for personvern</a> <span aria-label={readableValue}>{formattedValue}</span>.</p>
                    <p>
                        Dersom du ikke lenger ønsker at vi skal lagre og behandle dine personopplysninger,
                        så står du fritt til å slette din kundeprofil hos Vinmonopolet.
                        Hvis du sletter kontoen vil du ikke kunne logge inn og handle på Vinmonopolet.no eller i
                        Vinmonopolets app.
                        Vi vil kun beholde informasjon vi er pålagt å oppbevare i henhold til gjeldende lovverk.
                    </p>
                    <div className='form-buttons'>
                        <button className='btn-primary' onClick={this.oncloseAccount}>Slett min konto</button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <Page title='Personvern' backUrl={site.accountURL()} isSpinning={this.state.isLoading} className={'my-page'}>
               <section className='white medium-content top'>
                    <ErrorBox errors={this.state.error} className="margin-bottom"/>
                    {this.renderContent()}
                </section>
                <ConfirmModal isOpen={this.state.isOpen} onClose={this.closeModal} onConfirm={this.closeAccount} confirmButtonText='Slett konto'
                          heading='Advarsel' mainText='Vennligst bekreft at du ønsker å slette din konto på Vinmonopolet.no' />
            </Page>
        );
    }
}