import { SanityDocument } from '@sanity/client';
import { useContext } from 'react';
import { getFormattedDate } from '../cms/CMSComponent';
import { DataAttributeContext } from './DataAttributeContext';
import * as style from './Grid.module.less';
import GridItemImage from './GridItemimage';
import { ImageWithMetadata } from './SanityImage';

export interface NewsPage extends SanityDocument {
    slug: { current: string };
    title: string;
    subtitle: string;
    firstPublished: string;
    bannerImage: ImageWithMetadata;
}

export default function NewsArticleItem({ bannerImage, slug, firstPublished, title }: NewsPage) {
    const dataAttribute = useContext(DataAttributeContext);

    return (
        <li className={style.item}>
            <a href={`/om-oss/aktuelt/${slug?.current}`}>
                <GridItemImage image={bannerImage} />
                <time dateTime={firstPublished} data-sanity={dataAttribute?.('firstPublished')}>
                    {getFormattedDate(firstPublished)}
                </time>
                <h2>{title}</h2>
            </a>
        </li>
    );
}
