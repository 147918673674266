import { ClientConfig } from '@sanity/client';
import { VmpEnv } from '../cloudflare/types';

export function getConfig(env: VmpEnv): ClientConfig {
    return {
        projectId: env.SANITY_PROJECT_ID,
        dataset: env.SANITY_DATASET ?? 'development',
        useCdn: !env.PREVIEW_MODE,
        perspective: env.PREVIEW_MODE ? 'previewDrafts' : 'published',
        apiVersion: '2024-05-13',
        token: env.SANITY_TOKEN,
        stega: {
            enabled: env.PREVIEW_MODE,
            studioUrl: env.SANITY_STUDIO_URL,
        },
    };
}
