import { useState } from 'react';
import * as api from '../api';
import { site, userAPI } from '../config';
import Page from '../generic/Page';
import useBooleanState, { toBooleanState } from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import useStateWithSideEffect from '../utils/useStateWithSideEffect';
import { AddressT } from './Address';
import AddressListItem from './AddressListItem';
import AddressModal from './AddressModal';

interface Response {
    addresses: AddressT[];
}

function trackOpenModal(open: boolean) {
    if (open)
        gtag('event', 'modal_view', {
            modal_name: 'AddAddress',
        });
}

export default function AddressesPage() {
    const [addresses, setAddresses] = useState<AddressT[]>([]);
    const [isAddModalOpen, setAddModalOpen] = toBooleanState(useStateWithSideEffect(false as boolean, trackOpenModal));
    const [isLoading, setIsLoading] = useBooleanState();
    const [error, setError] = useState(null);

    const getAdresses = async () => {
        setError(null);
        setIsLoading.toTrue();
        try {
            const { addresses } = await api.get<Response>(userAPI.addressesURL());
            setAddresses(addresses);
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    useOnMount(getAdresses);

    const savedAddress = () => {
        setAddModalOpen.toFalse();
        getAdresses();
    };

    const defaultAddress = addresses.filter((x) => x.defaultAddress);
    const savedAddresses = addresses.filter((x) => !x.defaultAddress);

    return (
        <Page title="Mine adresser" backUrl={site.accountURL()} isSpinning={isLoading} error={error} className="my-page">
            <section className="form-section white top">
                <div className="form-section-info">
                    <h2>Leveringsadresser og telefonnummer</h2>
                    <p>Du kan velge blant disse adressene når du handler i nettbutikken.</p>
                    <p>
                        “Min adresse” settes automatisk som mottaker på din handlekurv. Dette bør være den adressen du foretrekker som
                        leveringsadresse eller mottaker ved utsjekk.
                    </p>
                    <p>“Mottakeradresser” er adresser du kan velge hvis du skal bestille varer til andre.</p>
                </div>
                {!!addresses.length && (
                    <div className="form-section-content form-group">
                        <h2 className="label">Min adresse</h2>
                        <ul className="card-list">
                            {defaultAddress.map((address) => (
                                <AddressListItem key={address.id} address={address} onChange={getAdresses} />
                            ))}
                        </ul>
                        {!!savedAddresses.length && (
                            <>
                                <h2 className="label">Mottakeradresser</h2>
                                <ul className="card-list">
                                    {savedAddresses.map((address) => (
                                        <AddressListItem key={address.id} address={address} onChange={getAdresses} />
                                    ))}
                                </ul>
                            </>
                        )}
                        <ul className="card-list">
                            <li className="flex-centered">
                                <button onClick={setAddModalOpen.toTrue}>Legg til ny adresse</button>
                            </li>
                        </ul>
                    </div>
                )}
            </section>
            <AddressModal isOpen={isAddModalOpen} onClose={setAddModalOpen.toFalse} onConfirm={savedAddress} />
        </Page>
    );
}
