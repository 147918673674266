import js from 'dedent';
import { tokenKey } from '../utils/authToken';

const ANALYTICS_ID_PROD = 'G-DEYJBVMZRC';
const ANALYTICS_ID_DEV = 'G-G5R4J3MCMY';

export interface Props {
    config?: object;
}

export default function GA4({ config = {} }: Props) {
    return (
        <>
            <script
                type="module"
                dangerouslySetInnerHTML={{
                    __html: js`
                        const ANALYTICS_ID = location.host === 'www.vinmonopolet.no' ? ${JSON.stringify(ANALYTICS_ID_PROD)} : ${JSON.stringify(ANALYTICS_ID_DEV)};
                        window.dataLayer = window.dataLayer || [];
                        window.gtag = function(){dataLayer.push(arguments)};
                        gtag("js", new Date());
                        gtag("config", ANALYTICS_ID, ${JSON.stringify({ ...config, currency: 'NOK' })});
                        const scriptElm = document.createElement('script');
                        scriptElm.src = 'https://www.googletagmanager.com/gtag/js?id='+ANALYTICS_ID;
                        document.head.append(scriptElm);
                        const user = JSON.parse(localStorage.getItem(${JSON.stringify(tokenKey)}))?.user;
                        if (user) {
                            gtag("config", ANALYTICS_ID, {
                                user_id: user.trackingId,
                                user_properties: {
                                    store: user.favouriteStore?.displayName,
                                    postcode: user.defaultAddress?.postalCode
                                }
                            });
                        }
                        addEventListener("error", function(event){
                            gtag("event", "exception", {
                                description: event.message,
                                stack: event.error instanceof Error ? event.error.stack : event.message,
                            });
                        });
`,
                }}
            ></script>
        </>
    );
}
