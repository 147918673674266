import { createContext, createElement, DetailedHTMLProps, HTMLAttributes, ReactNode, useContext } from 'react';

export interface SlotContext {
    componentName: string;
    component: ReactNode;
    componentProps?: any;
}

export const slotContext = createContext<SlotContext>({
    componentName: '',
    component: null,
    componentProps: {},
});

export default function Slot({ elm = 'div', ...props }: { elm?: string } & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>) {
    const { componentName, component, componentProps } = useContext(slotContext);
    return createElement(elm, { ...props, 'data-react-component': componentName, 'data-react-props': JSON.stringify(componentProps) }, component);
}
