import { useState } from 'react';
import * as api from '../../api';
import { orderURL } from '../../config/CartAPIConfig';
import { accountURL } from '../../config/SiteConfig';
import { ErrorBoxType } from '../../generic/ErrorBox';
import InfoBox from '../../generic/InfoBox';
import Page from '../../generic/Page';
import * as url from '../../utils/url';
import useBooleanState from '../../utils/useBooleanState';
import { useOnMount } from '../../utils/useComponentDidMount';

export default function CheckoutConfirmationPage() {
    const [isLoading, setIsLoading] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [data, setData] = useState<Response>();

    useOnMount(() => {
        const code = url.getIdFromPath(document.location) ?? '';
        if (!code) return;

        setIsLoading.toTrue();
        setError(null);

        api.get<Response>(orderURL(code)).then(setData).catch(setError).finally(setIsLoading.toFalse);
    });

    if (!data) return <Page isSpinning={isLoading} error={error} />;

    return (
        <Page title={data.confirmation.header} className="order-confirmation">
            <p>{data.confirmation.message1}</p>
            {data.confirmation.message2 && <p>{data.confirmation.message2}</p>}
            {data.confirmation.message3 && <p>{addLink(data.confirmation.message3, 'Min side', accountURL())}</p>}
            {data.confirmation.infoMessage && <InfoBox message={data.confirmation.infoMessage} />}

            <a className="btn-secondary" href="/">
                Fortsett å handle
            </a>
        </Page>
    );
}

interface Response {
    code: string;
    confirmation: {
        header: string;
        infoMessage?: string;
        message1: string;
        message2?: string;
        message3?: string;
    };
}

function addLink(message: string, linkText: string, url: string) {
    const index = message.toLowerCase().indexOf(linkText.toLowerCase());

    if (index == -1) {
        return message;
    }

    return (
        <>
            {message.substring(0, index)}
            <a href={url}>{linkText}</a>
            {message.substring(index + linkText.length)}
        </>
    );
}
