import { useEffect, useState } from 'react';
import * as api from '../api';
import { site, wishlistAPI } from '../config';
import EmptyState from '../generic/EmptyState';
import { ErrorBoxType } from '../generic/ErrorBox';
import Icon from '../generic/Icon';
import Page from '../generic/Page';
import { PaginationType } from '../model/types';
import { ProductCardDetails } from '../product/ProductCard';
import ProductList from '../search/ProductList';
import Authenticated from '../utils/Authenticated';
import preventDefault from '../utils/preventDefault';
import * as url from '../utils/url';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import AddListToCartAction from './AddListToCartAction';
import SortSelector, { SortValue } from '../sortSelector/SortSelector';

export default function WishListPage() {
    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [data, setData] = useState<Response>();

    const getWishList = (params: { page?: number; sort?: string } = {}, replaceHistory = false) => {
        const { page, sort } = getParams(params);

        setIsLoading.toTrue();
        setError(null);

        const sortParam = sort ? `sort=${sort}` : '';
        if (page > 0) {
            url.setUrl(`currentPage=${page}&${sortParam}`, replaceHistory);
        } else {
            url.setUrl(sortParam, replaceHistory);
        }

        const requestParams: Record<string, string> = { pageSize: '24', currentPage: page.toString() };
        if (sort) {
            requestParams.sort = sort;
        }
        api.get<Response>(wishlistAPI.getWishlistPagedURL(code), requestParams)
            .then((data) => {
                /* in case of bogus url currentPage param */
                if (page > 0 && page >= data.pagination.totalPages) return getWishList();
                setData(data);
            })
            .catch(setError)
            .finally(setIsLoading.toFalse);
    };

    const changeSorting = (oldCode: string, newCode: string) => {
        getWishList({ page: 0, sort: newCode }, true);
    };

    useOnMount(() => {
        setCode(url.getIdFromPath(document.location) ?? '');
    });

    useEffect(() => {
        if (!code) return;
        getWishList({}, true);

        window.addEventListener('popstate', () => {
            getWishList({}, true);
        });
    }, [code]);

    const downloadWishList = async (type: string) => {
        setIsLoading.toTrue();
        const { blob, name } = await api.get(wishlistAPI.getWishlistExportURL(code, type));
        downloadBlob(blob, name);
        setIsLoading.toFalse();
    };

    return (
        <Authenticated>
            {!data ? (
                <Page isSpinning={isLoading} error={error} />
            ) : (
                <Page
                    title={data.name}
                    subTitle={data.pagination.totalResults ? `${data.pagination.totalResults} produkter` : undefined}
                    backUrl={site.listOfListsURL()}
                    isSpinning={isLoading}
                    error={error}
                    className="wishlist-page"
                >
                    {!data.wishlistEntries.length ? (
                        <EmptyState icon="icon-cart" text="Listen er tom" />
                    ) : (
                        <>
                            <div className="wishlist-actions no-print">
                                <p className="wishlist-page__download-action">
                                    <Icon className="icon-download" renderWithSpan />
                                    Last ned listen i formatet{' '}
                                    <a href={site.wishlistExportPdfURL(code)} onClick={preventDefault(() => downloadWishList('pdf'))}>
                                        PDF
                                    </a>
                                    {' eller '}
                                    <a href={site.wishlistExportCsvURL(code)} onClick={preventDefault(() => downloadWishList('csv'))}>
                                        CSV (regneark)
                                    </a>
                                </p>
                                {data.userDefined && data.wishlistEntries.length <= 20 && <AddListToCartAction listCode={code} />}
                            </div>
                            <div className="sort-action">
                                <SortSelector values={data.sorts} onValueSelect={changeSorting} />
                            </div>
                            <div id="search-results" tabIndex={-1} className="section__productlist">
                                <ProductList
                                    products={data.wishlistEntries.map(({ product, creationDate }) => ({
                                        ...product,
                                        entryCreationDate: creationDate,
                                    }))}
                                    pagination={data.pagination}
                                    onGotoPage={(page) => {
                                        getWishList(getParams({ page }));
                                    }}
                                    numberOfProductsWidth={4}
                                    tracking_id="Liste"
                                    tracking_name={data.userDefined ? 'Brukerdefinert' : data.name}
                                    showReview
                                />
                            </div>
                        </>
                    )}
                </Page>
            )}
        </Authenticated>
    );
}

function downloadBlob(blob: Blob, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
}

function getParams({ page, sort }: { page?: number; sort?: string }) {
    const urlParams = new URLSearchParams(location.search);
    page = page ?? parseInt(urlParams.get('currentPage') ?? '0');
    sort = sort ?? urlParams.get('sort') ?? undefined;
    return { page, sort };
}

interface Response {
    code: string;
    name: string;
    pagination: PaginationType;
    sorts: SortValue[];
    userDefined: boolean;
    wishlistEntries: {
        code: string;
        product: ProductCardDetails;
        creationDate: { formattedValue: string; readableValue: string; value: string };
    }[];
}
