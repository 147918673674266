import ChangeEmailSection from '../../js/account/ChangeEmailSection';
import ChangePasswordSection from '../../js/account/ChangePasswordSection';
import ChangeProfileSection from '../../js/account/ChangeProfileSection';
import { accountURL } from '../../js/config/SiteConfig';
import Page from '../../js/generic/Page';
import HeaderFooterLayout, { AssetProps } from '../../js/layout/HeaderFooterLayout';
import Authenticated from '../../js/utils/Authenticated';

export default function () {
    return (
        <Authenticated>
            <Page title="Min profil" backUrl={accountURL()} className="my-page">
                <ChangeProfileSection />
                <ChangeEmailSection />
                <ChangePasswordSection />
            </Page>
        </Authenticated>
    );
}

export function StaticLayout({ assets }: AssetProps) {
    return <HeaderFooterLayout assets={assets} title="Min profil" robots="noindex, nofollow" page_title="Endre profil" />;
}
