import { useContext } from 'react';
import { DataAttributeContext } from './DataAttributeContext';
import { TaggedSanityDocument } from './Grid';
import * as style from './Grid.module.less';
import GridItemImage from './GridItemimage';
import { ImageWithMetadata } from './SanityImage';

export interface DrinkRecommendation extends TaggedSanityDocument {
    slug: { current: string };
    title: string;
    bannerImage: ImageWithMetadata;
}

export default function DrinkRecommendationItem({ bannerImage, slug, title, tags }: DrinkRecommendation) {
    const dataAttr = useContext(DataAttributeContext);
    return (
        <li className={style.item}>
            <a href={`/drikke-til-mat/${slug?.current}`}>
                <GridItemImage image={bannerImage} />
                <ul className={style.tags} data-sanity={dataAttr?.('tags')}>
                    {tags?.filter(Boolean).map((t) => <li key={t.name}>{t.name}</li>)}
                </ul>
                <h2>{title}</h2>
            </a>
        </li>
    );
}
