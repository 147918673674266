import logo from '../images/header/header-logo.svg';
import Analytics from '../js/analytics/ga4';
import Page from '../js/generic/Page';
import { AssetProps } from '../js/layout/HeaderFooterLayout';

export default function () {}

export function StaticLayout({ assets }: AssetProps) {
    return (
        <html lang="no">
            <head>
                <meta charSet="utf-8" />
                <title>Server Error</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link href={assets['main.css']} rel="stylesheet"></link>
                <Analytics />
            </head>
            <body>
                <header className="server-error-header">
                    <img src={logo} />
                </header>
                <main>
                    <Page title="Det skjedde desverre en feil i løsningen" className="informative-page">
                        <div className="informative-page__content">
                            <div className="informative-page__icon link__icon icon icon-stacking-bottles" />
                            <div className="informative-page__text">
                                <p>
                                    Vennligst prøv igjen senere
                                    <br />
                                    Vi beklager ulempen og takker for tålmodigheten
                                </p>
                                <p className="text-emphasized">Kontakt oss ved spørsmål</p>
                                <p>
                                    E-post: <a href="mailto:kundesenter@vinmonopolet.no">kundesenter@vinmonopolet.no</a>
                                </p>
                                <p>
                                    Telefon: <a href="tel:22015000">22 01 50 00</a>
                                </p>
                            </div>
                        </div>
                    </Page>
                </main>
            </body>
        </html>
    );
}
