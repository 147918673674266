import { useEffect, useState } from 'react';
import * as api from '../api';
import CartEntry, { Entry } from '../cart/CartEntry';
import { site, userAPI } from '../config';
import Page from '../generic/Page';
import * as url from '../utils/url';
import useBooleanState from '../utils/useBooleanState';
import OrderDetails, { OrderDetailsProps } from './OrderDetails';
import OrderSummary, { Amounts } from './OrderSummary';

interface Order extends OrderDetailsProps {
    amounts: {
        amounts: Amounts[];
        total: Amounts;
    };
    entries: Entry[];
}

export default function OrderDetailsPage() {
    const [order, setOrder] = useState<Order | null>(null);
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const orderCode = url.getIdFromPath(document.location) ?? '';
        api.get(userAPI.orderDetailsURL(orderCode)).then(setOrder).catch(setError).finally(setIsLoading.toFalse);
    }, []);

    return (
        <Page title="Kjøp" backUrl={site.ordersURL(location.search)} isSpinning={isLoading} error={error} className="my-page">
            {order != null && (
                <div className="order-details main-side-columns reverse-columns">
                    <div className="side-column">
                        <OrderDetails status={order.status} summary={order.summary} />
                    </div>
                    <div className="main-column">
                        <ul className="product-list product-list-horizontal">
                            {order.entries.map((entry) => (
                                <CartEntry key={entry.entryNumber} entry={entry} />
                            ))}
                            <li key="summary">
                                <OrderSummary amounts={order.amounts} />
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </Page>
    );
}
