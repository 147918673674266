import { ReactPageFunction } from '../../../js/cloudflare/types';
import SanityHeaderFooterLayout, { SanityPageDocument } from '../../../js/layout/SanityHeaderFooterLayout';
import Grid from '../../../js/sanity/Grid';
import NewsArticleItem, { NewsPage } from '../../../js/sanity/NewsArticleItem';
import { imageWithMetadataGroq } from '../../../js/sanity/SanityImage';
import SanityPage from '../../../js/sanity/SanityPage';
import getSanityPageProps, { SanityModule, SanityProps } from '../../../js/sanity/getSanityPageProps';
import groq from '../../../js/sanity/groq';
import { setCacheControl } from '../../../js/utils/responseHelpers';

interface ListPage extends SanityPageDocument {
    list: NewsPage[];
}

const query = groq`
    *[_type == "listPage" && slug.current == "aktuelt"][0]{
        _id,
        _type,
        slug,
        title,
        ingress,
        metadata,
        'list': *[_type == ^.listType]{
            _id,
            _type,
            slug,
            title,
            ingress,
            firstPublished,
            bannerImage ${imageWithMetadataGroq}
        } | order(firstPublished desc)
    }
`;
export const onRequestGet: ReactPageFunction<'/om-oss/aktuelt', SanityProps<ListPage>> = async ({ renderToResponse, next, ...ctx }) => {
    const response = await getSanityPageProps<ListPage>(ctx, query);

    if (response.notFound) {
        return next();
    }

    return renderToResponse(response) //
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<ListPage>(({ page }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <Grid list={page.list} component={NewsArticleItem} />
    </article>
));

export { SanityHeaderFooterLayout as StaticLayout };

import * as __SELF__ from './';
__SELF__ satisfies SanityModule<ListPage>;
