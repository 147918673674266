import {
    PortableText,
    PortableTextBlock,
    PortableTextComponentProps,
    PortableTextComponents,
    PortableTextMarkComponentProps,
} from '@portabletext/react';
import { StudioPathLike } from '@sanity/react-loader';
import { useContext } from 'react';
import { DataAttributeContext, ScopeDataAttribute } from './DataAttributeContext';
import SanityImage, { CaptionedSanityImage, type ImageWithMetadata, imageWithMetadataGroq } from './SanityImage';
import groq from './groq';

const LinkCardImageSizes = [250, 500];

const linkCardGroq = groq`
{
    reference -> {
        _id,
        _type,
        title,
        parent,
        slug,
        bannerImage ${imageWithMetadataGroq}
    },
    linkText,
    kicker,
    showImage
}
`;

function LinkCard({ value }: PortableTextComponentProps<any>) {
    const attr = useContext(DataAttributeContext);
    return (
        <a className="link-card" href={getLink(value?.reference)} data-sanity={attr?.([{ _key: value._key }])}>
            <h2>
                <span>{value.kicker}</span>
                {value.linkText || value.reference.title}
            </h2>
            {value.showImage && <SanityImage image={value.reference.bannerImage} sizes="250px" widths={LinkCardImageSizes} aspectRatio={16 / 9} />}
        </a>
    );
}

function FactBox({ value }: PortableTextComponentProps<any>) {
    const attr = useContext(DataAttributeContext);
    return (
        <aside className="fact-box" data-sanity={attr?.([{ _key: value._key }])}>
            <h2>{value.title}</h2>
            <SimpleRichText value={value.simpleRichText} />
        </aside>
    );
}

const imageSizes = [320, 640, 1024, 1288];
function ImageWithMetadata({ value }: PortableTextComponentProps<ImageWithMetadata & { _key: string }>) {
    return (
        <CaptionedSanityImage
            image={value}
            widths={imageSizes}
            sizes="(min-width: 1288) 1288px, 100vw"
            studioPath={[{ _key: value._key }]}
            loading="lazy"
        />
    );
}

const externalLinkGroq = groq`
{
    _type,
    href,
    blank
}
`;

export function ExternalLink({
    value,
    children,
}: PortableTextMarkComponentProps<{
    _type: 'link';
    href?: string;
    blank?: boolean;
}>) {
    return (
        <a href={value?.href} target={value?.blank ? '_blank' : undefined} rel={value?.blank ? 'noreferrer' : undefined}>
            {children}
        </a>
    );
}

export const internalLinkGroq = groq`
{
    _type,
    internalReference -> {
        _type,
        slug
    },
    anchor
}
`;

function InternalLink({
    value,
    children,
}: PortableTextMarkComponentProps<{
    _type: 'internalLink';
    internalReference?: {
        _type: string;
        slug?: { current: string };
    };
    anchor?: string;
}>) {
    return <a href={getLink(value?.internalReference, value?.anchor)}>{children}</a>;
}

function getLink({ _type, slug }: { _type: string; slug?: { current: string } } = { _type: '' }, anchor?: string) {
    anchor = anchor ? `#${anchor}` : '';
    switch (_type) {
        case 'newsArticle':
            return `/om-oss/aktuelt/${slug?.current}${anchor}`;
        case 'recipeArticle':
            return `/oppskrifter/${slug?.current}${anchor}`;
        case 'drinkRecommendationArticle':
            return `/drikke-til-mat/${slug?.current}${anchor}`;
        default:
            return `/404?_type=${_type}${anchor}`;
    }
}

const marks = {
    link: ExternalLink,
    internalLink: InternalLink,
};

const types = {
    linkCard: LinkCard,
    fact: FactBox,
    imageWithMetadata: ImageWithMetadata,
};

const richTextComponents: Partial<PortableTextComponents> = {
    types,
    marks,
};

const simpleRichTextComponents: Partial<PortableTextComponents> = {
    marks,
};

const markDefsGroq = groq`{
    ...,
    _type == "link" => ${externalLinkGroq},
    _type == "internalLink" => ${internalLinkGroq}
}`;

export const richTextGroq = groq`{
    ...,
    _type == "fact" => @-> {
        title,
        simpleRichText[]
        {
            ...,
            markDefs[] ${markDefsGroq},
        },
    },
    _type == "imageWithMetadata" => ${imageWithMetadataGroq},
    _type == "linkCard" => ${linkCardGroq},
    markDefs[] ${markDefsGroq},
}`;

export function SimpleRichText({ value }: { value: PortableTextBlock }) {
    return <PortableText value={value} components={simpleRichTextComponents} />;
}

export function RichText({ value, studioPath }: { value: PortableTextBlock; studioPath?: StudioPathLike }) {
    return (
        <ScopeDataAttribute path={studioPath ?? 'richText'}>
            <PortableText value={value} components={richTextComponents} />
        </ScopeDataAttribute>
    );
}
