import { AssetProps } from './HeaderFooterLayout';

export default function Favicons({ assets }: AssetProps) {
    const images = Object.entries(assets).filter(([key]) => key.startsWith('assets/favicons/'));

    const appleTouchIcons = images
        .map(([key, value]) => [/apple-touch-icon-(\d+x\d+)/.exec(key)?.[1], value] as const)
        .filter((a): a is [string, string] => !!a[0]);

    const favicons = images.map(([key, value]) => [/favicon-(\d+x\d+)/.exec(key)?.[1], value] as const).filter((a): a is [string, string] => !!a[0]);

    return (
        <>
            {appleTouchIcons.map(([size, href]) => (
                <link key={href} rel="apple-touch-icon" sizes={size} href={href} />
            ))}
            <link rel="apple-touch-icon-precomposed" href={assets['assets/favicons/apple-touch-icon-precomposed.png']} />
            {favicons.map(([size, href]) => (
                <link key={href} rel="icon" type="image/png" sizes={size} href={href} />
            ))}
            <link rel="shortcut icon" type="image/x-icon" href={assets['assets/favicons/favicon.ico']} />
            <link rel="manifest" href={assets['assets/favicons/manifest.webmanifest']} />

            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content={assets['assets/favicons/mstile-144x144.png']} />
            <meta name="theme-color" content="#ffffff" />
        </>
    );
}
