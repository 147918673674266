import { SanityDocument } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { SanityProps } from '../sanity/getSanityPageProps';
import { ImageWithMetadata } from '../sanity/SanityImage';
import HeaderFooterLayout, { MetaTag, StaticLayoutProps } from './HeaderFooterLayout';

export interface Metadata {
    title?: string;
    description?: string;
    language?: string;
    noIndex?: boolean;
}
export interface SanityPageDocument extends SanityDocument {
    parent: { _ref: string };
    title?: string;
    slug: { current: string };
    ingress: string;
    metadata?: Metadata;
    bannerImage?: ImageWithMetadata;
}

export default function SanityHeaderFooterLayout<T extends SanityPageDocument, P = undefined>({
    assets,
    url,
    props,
}: StaticLayoutProps<SanityProps<T, P>>) {
    const canonical = getCanonical(url);

    const initial = props.initial.data;

    const image = getImageUrl(initial.bannerImage, imageUrlBuilder(props.sanityProjectDetails));

    return (
        <HeaderFooterLayout
            assets={assets}
            title={initial.title}
            meta={getMetadata(initial.metadata, image, canonical)}
            canonical={canonical}
            robots={initial.metadata?.noIndex ? 'noindex,nofollow' : 'all'}
        />
    );
}

function getCanonical(url: string) {
    const u = new URL(url);
    u.hash = '';
    u.search = '';
    return u.toString();
}

function getMetadata(metadata: Metadata | undefined, image: string | undefined | undefined, canonical: string): MetaTag[] {
    return metadata
        ? [
              { name: 'description', content: metadata.description },
              { property: 'og:locale', content: langToLocale(metadata.language) },
              { property: 'og:type', content: 'website' },
              { property: 'og:site_name', content: 'vinmonopolet.no' },
              { property: 'twitter:card', content: 'summary_large_image' },
              { property: 'og:description', content: metadata.description },
              { property: 'og:title', content: metadata.title },
              { property: 'og:url', content: canonical },
              { property: 'og:image', content: image },
          ]
        : [{ property: 'og:url', content: canonical }];
}

function getImageUrl(image: ImageWithMetadata | undefined, builder: ImageUrlBuilder) {
    if (!image) return undefined;
    return builder.image(image).width(1200).height(630).format('jpg').url();
}

function langToLocale(lang: string | undefined) {
    switch (lang) {
        case 'nb':
            return 'nb_NO';
        case 'nn':
            return 'nn_NO';
        case 'en':
            return 'en_US';
        default:
            return 'nb_NO';
    }
}
