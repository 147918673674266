import { ReactPageFunction } from '../js/cloudflare/types';
import SanityHeaderFooterLayout, { SanityPageDocument } from '../js/layout/SanityHeaderFooterLayout';
import Grid from '../js/sanity/Grid';
import SanityPage from '../js/sanity/SanityPage';
import { WineMagazine, WineMagazineProps, wineMagazineGroq } from '../js/sanity/WineMagazine';
import getSanityPageProps, { SanityProps } from '../js/sanity/getSanityPageProps';
import { setCacheControl } from '../js/utils/responseHelpers';

interface ListPage extends SanityPageDocument {
    list: WineMagazineProps[];
}

const query = /* groq */ `
    *[_type == "listPage" && slug.current == "vinbladet"][0]{
        _id,
        _type,
        slug,
        title,
        ingress,
        metadata,
        'list': *[_type == ^.listType]${wineMagazineGroq} | order(releaseNumber desc)
    }
`;
export const onRequestGet: ReactPageFunction<'/vinbladet', SanityProps<ListPage>> = async ({ renderToResponse, next, ...ctx }) => {
    const response = await getSanityPageProps<ListPage>(ctx, query);

    if (response.notFound) {
        return next();
    }

    return renderToResponse(response) //
        .then(setCacheControl(`public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
};

export default SanityPage<ListPage>(({ page }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <div className="breadcrumbs"></div>
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <section>
            <aside className="vinbladet">
                <h2>Gratis abonnement på Vinbladet</h2>
                <p>
                    Logg inn eller opprett en bruker på <a href="/my-account">Min side</a>. Deretter krysser du av for å få kundemagasinet Vinbladet
                    rett hjem i postkassen fire ganger i året.
                </p>
            </aside>
        </section>
        <Grid list={page.list} component={WineMagazine} />
    </article>
));

export { SanityHeaderFooterLayout as StaticLayout };
