import { useCallback, useRef, useState } from 'react';
import * as api from '../api';
import { storeAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import InfoBox from '../generic/InfoBox';
import Pagination from '../generic/Pagination';
import Spinner from '../generic/Spinner';
import { PaginationType } from '../model/types';
import useBooleanState from '../utils/useBooleanState';
import StoreInfo, { StoreWithDistance } from './StoreInfo';
import StoreLocatorForm from './StoreLocatorForm';

interface Props {
    onSelectPointOfService(storeName: string): void;
    showBroadcastMessage?: boolean;
    showArrow?: boolean;
    pageSize?: number;
}

export interface StoreResponse {
    broadcastMessage?: string;
    storeDistanceMessage?: string;
    pagination: PaginationType;
    betweenStoresMessage: string;
    stores: StoreWithStock[];
}

interface StoreWithStock extends StoreWithDistance {
    pointOfService: StoreWithDistance;
    stockInfo: { stockLevel: number };
}

export default function StoreLocator({ onSelectPointOfService, showBroadcastMessage = false, showArrow = false, pageSize = 10 }: Props) {
    const [infoMessage, setInfoMessage] = useState<string>();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useBooleanState();
    const [searchResults, setSearchResults] = useState<StoreResponse | null>(null);
    const queryParams = useRef<Record<string, string>>({ pageSize: pageSize.toString(), currentPage: '0', fields: 'BASIC' });

    const query = useCallback((params: Record<string, string>) => {
        setInfoMessage(undefined);
        setIsLoading.toTrue();
        setError(null);

        queryParams.current = { ...queryParams.current, ...params };
        api.getAnonymously(storeAPI.storesURL(), queryParams.current).then(setSearchResults).catch(setError).finally(setIsLoading.toFalse);
    }, []);

    const changePage = useCallback((page: number) => {
        query({ currentPage: page.toString() });
    }, []);

    return (
        <div className="store-locator">
            <Spinner isSpinning={isLoading} />
            {showBroadcastMessage && searchResults != null && <InfoBox message={searchResults.broadcastMessage} />}
            <StoreLocatorForm
                onLocationChange={({ latitude, longitude }) =>
                    query({ currentPage: '0', latitude: latitude.toString(), longitude: longitude.toString() })
                }
                onFail={setError}
                onLocationFail={setInfoMessage}
                onItemSelected={onSelectPointOfService}
            />
            <ErrorBox errors={error} />
            <InfoBox message={infoMessage} />
            {searchResults?.stores.length === 0 && <div className="store-locator__message">Vi fant ikke noe sted som passer til søkeordet.</div>}
            {!!searchResults && (
                <>
                    <p>{searchResults.storeDistanceMessage}</p>
                    <ul className="store-list" role="tablist">
                        {searchResults.stores.map((store) => (
                            <StoreInfo key={store.name} store={store} onClickSuggestion={onSelectPointOfService} showArrow={showArrow} />
                        ))}
                    </ul>
                    <Pagination {...searchResults.pagination} onGotoPage={changePage} />
                </>
            )}
        </div>
    );
}
