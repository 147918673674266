import * as site from '../config/SiteConfig';
import AltSR from '../generic/AltSR';
import Icon from '../generic/Icon';
import * as style from '../order/order-history.module.less';

interface Props extends OrderDetails {
    currentPage?: number;
    completed: boolean;
}

export interface OrderDetails {
    code: string;
    summary: { caption: string; value: string; readableValue?: string }[];
    total: { currencyIso: string; formattedValue: string; priceType: string; readableValue: string; value: number };
    orderType: string;
    creationDate: { formattedValue: string; readableValue: string; value: string };
}

export default function OrderHistoryEntry({ currentPage = 0, completed, code, summary, total, orderType, creationDate }: Props) {
    const orderCompleted = completed ? 'completed' : 'active';
    const pageParam = `orderType=${orderCompleted}` + (currentPage > 0 ? `&currentPage=${currentPage}` : '');

    return (
        <a href={site.orderURL(code, pageParam)} className={style.item}>
            <div className={style.info}>
                <div className={style.orderType}>
                    <span>{orderType}</span>
                    <AltSR text={creationDate.formattedValue} sr={`${orderType}: ${creationDate.readableValue}`} />
                </div>
                <div className={style.content}>
                    {summary.map((summaryEntry) => (
                        <div key={summaryEntry.caption}>
                            <span className={style.caption}>{`${summaryEntry.caption}: `}</span>
                            <AltSR srPrefix={`${summaryEntry.caption}: `} text={summaryEntry.value} />
                        </div>
                    ))}
                </div>
                <AltSR text={total.formattedValue} sr={`Totalbeløp: ${total.readableValue}`} />
            </div>
            <div className={style.arrow}>
                <Icon className="icon-chevron-right" />
            </div>
        </a>
    );
}
