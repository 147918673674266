// extracted by mini-css-extract-plugin
var _1 = "block-V4bujGgG";
var _2 = "content-item-wLPXgMvT";
var _3 = "content-item-wLPXgMvT";
var _4 = "horizontal-fV4gfn6W";
var _5 = "label-V4pFkEll";
var _6 = "properties-_He_CJM6";
var _7 = "properties-item-X1TYnx0W";
var _8 = "properties-list-xkR5ixJv";
var _9 = "properties-item-X1TYnx0W";
var _a = "properties-list-xkR5ixJv";
var _b = "style-button-WAok3y5i";
var _c = "style-label-e3cFwV3a label-V4pFkEll";
var _d = "style-popover-LzV3jwFl";
var _e = "style-button-WAok3y5i";
var _f = "style-label-e3cFwV3a label-V4pFkEll";
var _10 = "style-popover-LzV3jwFl";
var _11 = "wrapper-uHJQsghf";
export { _1 as "block", _2 as "content-item", _3 as "contentItem", _4 as "horizontal", _5 as "label", _6 as "properties", _7 as "properties-item", _8 as "properties-list", _9 as "propertiesItem", _a as "propertiesList", _b as "style-button", _c as "style-label", _d as "style-popover", _e as "styleButton", _f as "styleLabel", _10 as "stylePopover", _11 as "wrapper" }
